
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UserManagementService } from '../user-management/user-management.service';

@Component({
  selector: 'app-lab-header',
  templateUrl: './lab-header.component.html',
  styleUrls: ['./lab-header.component.css']
})
export class LabHeaderComponent implements OnInit,AfterViewInit {
  public isCollapsed = false;
  public isMenuCollapsed = true;
  public selectedSite = '';
  public sitesList: any[] = [];
  public siteinfo:any = [];
  ch_pass : any;
  labSidebardisplay: boolean;
  changePasswordShow : boolean;
  constructor(private userService: UserManagementService) { }

  ngOnInit(): void {
    this.siteinfo = this.userService.getSiteDetail();
    if(this.siteinfo?.length){
      this.ch_pass=this.siteinfo[0].changePassword;
 
    }
    this.onSiteAdded();
  }
  ngAfterViewInit() {
    this.getSiteList();
  }

  getSiteList() {
    const siteList = this.userService.getSiteDetail();
    if (siteList && siteList.length) {
      this.sitesList = siteList;
      const userDetail = this.userService.getUserDetail();
      if (userDetail && userDetail.role == 1 && this.sitesList && this.sitesList.length) {
        setTimeout(() => {
          this.selectedSite = this.sitesList[0].site_id;
          this.onSiteChange(this.selectedSite);
        })
      }
    }
  }

  onSiteChange(event) {
    this.userService.updateMessage({ 'purpose': 'sitechanged', 'value': event });
    this.userService.selectedSite = this.selectedSite;
  }

  onSiteAdded() {
    this.userService.getMessage().subscribe(data => {
      if (data.purpose === 'siteadded') {
        this.sitesList = data.value;
        if (this.sitesList && this.sitesList.length) {
          const allSiteIds = this.sitesList.map(ele => ele.site_id);
          if (!allSiteIds.includes(this.selectedSite)) {
            this.selectedSite = '';
            setTimeout(() => {
              this.selectedSite = allSiteIds[0];
              this.userService.selectedSite = String(this.selectedSite);
            });
          } else {
            this.selectedSite = '';
            setTimeout(() => this.selectedSite = String(this.userService.selectedSite));
          }
        }
      }
    });
  }
  onChange() {
    this.selectedSite = null;
    this.selectedSite = this.userService.selectedSite;
  }
  logout() {
    this.userService.logout();
  }
  changePass(){
   this.labSidebardisplay = true;
   this.changePasswordShow = true;
  }
  onchPassCancel() {
    this.labSidebardisplay = false;
  }
  changePassSaved(){
    this.labSidebardisplay = false;
  }
}
