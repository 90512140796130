<div class="scroll_y">
    <div class="card border-0">
      <div class="card-body">
        <h4 class="pl-2 mb-4 mt-3">Change Password</h4>
        <form class="mb-160px" [formGroup]="chForm" (ngSubmit)="chSubmit($event)">
          <div class="form-body text-black">
            <div class="row">
              <div class="col-md-12 mb-2">
                <div class="form-group">
                  <label>Old Password <sup class="text-danger">*</sup></label>
                  <input type="password" formControlName="old_password" name="old_password" class="form-control" placeholder="Old Password">
                  <small class="text-danger" *ngIf="chForm.touched && !chForm.get('old_password').value">Old Passwrod is requried.</small>
                </div>
              </div>
              <div class="col-md-12 mb-2">
                <div class="form-group">
                  <label>New Password <sup class="text-danger">*</sup></label>
                  <input type="password" formControlName="new_password" name="new_password" class="form-control" placeholder="New Password">
                  <small class="text-danger" *ngIf="chForm.touched && !chForm.get('new_password').value">Old Passwrod is requried.</small>
                  <small class="text-danger" *ngIf="chForm.touched && chForm.get('new_password').hasError('pattern')">
                    Password should contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character.
                  </small>
                </div>
              </div>
              <div class="col-md-12 mb-2">
                <div class="form-group">
                  <label>Re-enter Password <sup class="text-danger">*</sup></label>
                  <input type="password" formControlName="re_password" name="re_password" class="form-control" placeholder="Re-enter Password">
                  <small class="text-danger" *ngIf="chForm.touched && !chForm.get('re_password').value">Re-enter Passwrod is requried.</small>
                  <small class="text-danger" *ngIf="chForm.touched && chForm.get('re_password').hasError('pattern')">
                    Password should contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character.
                  </small>
                </div>
              </div>
            </div>
          
          </div>
          <div class="form-actions col-12">
            <button type="submit" class="btn btn-sm  mr-1 btn-add-site-user text-white pr-2 mr-4 " [disabled]="chForm.invalid">Save </button>
              <button type="reset" class="btn btn-sm  mr-1 btn-cancel" (click)="onCancel()">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  